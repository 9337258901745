import EmailIcon from '@mui/icons-material/Email';
import RestartIcon from '@mui/icons-material/RestartAlt';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { Alert, Grid } from '@mui/material';
import { Theme } from 'ui/theme';

function resetApp() {
  return window.location.reload();
}


function AppErrorBoundaryFallback() {
  return (
    <Theme>

    
      <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: '100vh'}}>
    <Box height={400}>
        <Paper sx={{ p: 5 }} elevation={20}>
          <Typography variant="h6" component="h3">
            Es scheint ein Fehler aufgetreten zu sein. Sie können:
          </Typography>
          <Button startIcon={<RestartIcon />} color="primary" sx={{ my: 2 }} variant="outlined" onClick={resetApp}>
            Die Seite neu Laden
          </Button>
          <Typography>oder</Typography>
          <Button
          color="primary"
            startIcon={<EmailIcon />}
            variant="outlined"
            target="_blank"
            rel="noreferrer"
            href={`mailto:marktverwaltung@klagenfurt.at`}
            sx={{ my: 2 }}
          >
            Uns per E-Mail Kontaktieren
          </Button>
          <Typography>oder</Typography>

          <Alert severity='info' sx={{ mt: 2 }} onClick={resetApp}>
            Den Cache mit der Tastenkombination <b>"STRG + SHIFT + R" (Windows)</b> oder <b>"OPTION + COMMAND + E" (Apple)</b> löschen.
          </Alert>
         
        </Paper>
    </Box>
      </Grid>
    </Theme>
  );
}

export default AppErrorBoundaryFallback;
