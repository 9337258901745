import React from 'react';
import { CircularProgress, Box, Grid } from '@mui/material';

export const Loader = ({ loading }) => {
  return (
    <Box bgcolor="primary.main">
      <Grid
        container
        style={{
          height: '100vh',
          position: 'fixed',
          top: 0,
          opacity: loading ? 1 : 0,
          visibility: loading ? 'visible' : 'hidden',
          transition: '0.5s',
          zIndex: 100,
          backgroundColor: '#f5f5f5',
        }}
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" />
      </Grid>
    </Box>
  );
};
