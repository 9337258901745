import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import 'core-js/features/array/from';

import './scss/tailwind.css';
import './scss/index.min.css';

import dayjs from 'dayjs';
import 'dayjs/locale/de';

import React from 'react';
import { createRoot } from 'react-dom/client';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { BrowserRouter } from 'react-router-dom';
import App from './App';
// import reportWebVitals from './reportWebVitals';

dayjs.locale('de');
const container = document.getElementById('root');

const root = createRoot(container);

root.render(
  <>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
serviceWorkerRegistration.register();
