import React, { Suspense } from 'react';
import CssBaseline from '@mui/material/CssBaseline';

import { AuthProvider, useAuth } from './services/auth-provider';
import ScrollRestoration from './router/scrollRestoration';
import { Theme } from 'ui/theme';

import { QueryClient, QueryClientProvider } from 'react-query';
import { withErrorHandler } from 'error-handling';
import AppErrorBoundaryFallback from 'error-handling/fallbacks/App';

const AuthenticatedApp = React.lazy(() => import('./AuthenticatedApp'));
const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: false,
    },
  },
});

const App = () => {
  return (
    <Suspense fallback={null}>
      <CssBaseline />
      <ScrollRestoration>
        <QueryClientProvider contextSharing={true} client={queryClient}>
          <Theme>
            <AuthProvider>
              <AuthSwitcher />
            </AuthProvider>
          </Theme>
        </QueryClientProvider>
      </ScrollRestoration>
    </Suspense>
  );
};

const AuthSwitcher = () => {
  const { user } = useAuth();

  return user && user?.role !== 'guest' ? (
    <AuthenticatedApp />
  ) : (
    <UnauthenticatedApp />
  );
};

export default withErrorHandler(App, AppErrorBoundaryFallback);
